<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(255, 255, 255, 0.3)'"
    :style="sidebarColor"
    app
    permanent
    expand-on-hover
    mini-variant-width="80"
    v-bind="$attrs"
    v-show="$route.name != 'LoadReport'"
  >
    <!-- <template v-slot:img="props">
      <v-img
        :gradient="`to bottom, ${barColor}`"
        v-bind="props"
      />
    </template> -->
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="text-uppercase font-weight-regular display-2">
          <span class="logo-mini hide-on-over">{{ navbarCt ? navbarCt : $t('ct') }}</span>
          <span class="logo-normal">{{ navbarTitle ? navbarTitle : $t('tim') }}</span>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list dense nav>
      <base-item-group :item="profile" />
    </v-list>

    <v-divider class="mb-2" />

    <v-list expand nav>
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />

      <template v-for="(item, i) in computedItems">
        <base-item-group v-if="item.children" :key="`group-${i}`" :item="item">
          <!--  -->
        </base-item-group>

        <base-item v-else :key="`item-${i}`" :item="item" class="orangish" />
      </template>

      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
    </v-list>
    <template v-slot:append>
      <v-list-item class="my-5">
        <v-list-item-title>
          <span class="logo-mini">
            <img :src="blitzIcon" height="48px" v-if="blitzIcon" />
            <!-- <img src="@/assets/partner/star21-icon.png" height="48px"/> -->
          </span>
          <span class="logo-normal">
            <img :src="blitzLogo" height="48px" v-if="blitzLogo" />
            <!-- <img src="@/assets/partner/star21-logo.png" height="48px"/> -->
          </span>
        </v-list-item-title>
        <v-list-item-content> </v-list-item-content>
      </v-list-item>
    </template>
  </v-navigation-drawer>
</template>

<script>
// Utilities
import { mapGetters, createNamespacedHelpers } from 'vuex'
const { mapActions } = createNamespacedHelpers('app')

export default {
  name: 'DashboardCoreDrawer',

  props: {
    expandOnHover: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    items: [
      {
        icon: 'mdi-finance',
        title: 'dashboard',
        to: '/'
      },
      {
        icon: 'mdi-developer-board',
        title: 'serviceDetails',
        to: '/pages/serviceDetails'
      },
      {
        icon: 'mdi-hexagon-multiple',
        title: 'plans',
        to: '/pages/plans'
      },
      {
        icon: 'mdi-cellphone-link',
        title: 'equipment',
        to: '/pages/equipment'
      },
      {
        icon: 'mdi-cellphone-message',
        title: 'usage',
        to: '/pages/usage'
      },
      {
        icon: 'mdi-clipboard-edit-outline',
        title: 'configuredOptions',
        to: '/pages/configuredOptions'
      }
      // {
      //   icon: 'mdi-upload',
      //   title: 'settings',
      //   to: '/pages/settings',
      // },
    ]
  }),

  computed: {
    ...mapGetters(['appDrawer', 'barColor', 'barImage']),
    ...mapGetters('app', {
      customerInfo: 'customerInfo'
    }),
    drawer: {
      get() {
        return this.appDrawer
      },
      set(val) {
        this.setAppDrawer(val)
      }
    },
    computedItems() {
      return this.items.map(this.mapItem)
    },
    profile() {
      return {
        avatar: true,
        group: '',
        title: this.$t('avatar')
      }
    },
    blitzIcon: function () {
      return this.customerInfo.blitzIcon
    },
    blitzLogo: function () {
      return this.customerInfo.blitzLogo
    },
    navbarTitle: function () {
      return this.customerInfo.navbarTitle
    },
    sidebarColor: function () {
      return 'background-color:' + this.customerInfo.sidebarColor + ';'
    },
    sidebarTextColor: function () {
      return this.customerInfo.sidebarTextColor
    },
    navbarCt: function () {
      let ct
      if (this.customerInfo.navbarTitle) {
        let ctArr = this.customerInfo.navbarTitle.split(' ')
        ct = ((ctArr[0] ? ctArr[0].substring(0, 1) : '') + (ctArr[1] ? ctArr[1].substring(0, 1) : '')).toUpperCase()
      }
      return ct
    },
    primaryColor: function () {
      return this.customerInfo.activeButtonColor
    }
  },

  watch: {
    '$vuetify.breakpoint.smAndDown'(val) {
      this.$emit('update:expandOnHover', !val)
    },
    customerInfo: {
      handler() {
        this.$vuetify.theme.themes.light.primary = this.primaryColor
        document.documentElement.style.setProperty('--text-color', this.sidebarTextColor);
      }
    }
  },

  mounted() {
    if (process.env?.NODE_ENV == 'development') {
      this.items.push({
        icon: 'mdi-upload',
        title: 'settings',
        to: '/pages/settings'
      })
    }
    // console.log('environment is ', process.env?.NODE_ENV)
  },
  methods: {
    ...mapActions(['setAppDrawer']),
    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: this.$t(item.title)
      }
    }
  }
}
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'
.v-list-item
  &__icon,
  &__title,
    .v-icon
      color: var(--text-color) !important

#core-navigation-drawer
  &.v-navigation-drawer--mini-variant
    .v-list-item
      justify-content: flex-start !important

    .v-list-group--sub-group
      display: block !important

  .v-list-group__header.v-list-item--active:before
    opacity: .24

  .v-list-item
    &__icon--text,
    &__icon:first-child
      justify-content: center
      text-align: center
      width: 20px

      +ltr()
        margin-right: 24px
        margin-left: 12px !important

      +rtl()
        margin-left: 24px
        margin-right: 12px !important

  .v-list--dense
    .v-list-item
      &__icon--text,
      &__icon:first-child
        margin-top: 10px

  .v-list-group--sub-group
    .v-list-item
      +ltr()
        padding-left: 8px

      +rtl()
        padding-right: 8px

    .v-list-group__header
      +ltr()
        padding-right: 0

      +rtl()
        padding-right: 0

      .v-list-item__icon--text
        margin-top: 19px
        order: 0

      .v-list-group__header__prepend-icon
        order: 2

        +ltr()
          margin-right: 8px

        +rtl()
          margin-left: 8px
// a.orangish.primary
//   background-color: #ee3b34 !important
//   border-color: #ee3b34 !important

.v-navigation-drawer--is-mouseover .hide-on-over
  display: none
</style>
